var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"flat":""}},_vm._l((_vm.shippingItemsByVehicle),function(value,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',{staticClass:"body-1"},[_vm._v("Móvil "+_vm._s(key)+" "),_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(value.length)+")")])]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","hide-default-header":"","item-key":"id","headers":_vm.headers,"items":value,"items-per-page":-1,"loading":_vm.isLoading,"no-data-text":"No hay pedidos para mostrar","sort-by":"id","sort-desc":"","mobile-breakpoint":0},on:{"click:row":_vm.onRowSelected},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var toggle = ref.toggle;
return [_c('td',[_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"icon":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('span',{staticClass:"title"})],1)]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getCustomer(_vm.getOrder(item.orderId).customer).fullName))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 d-none d-sm-inline grey--text"},[_vm._v("mdi-cart-outline")]),_vm._v(" "+_vm._s(item.orderId)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('tui-shipping-state-chip',{attrs:{"shrink":true,"state":item.state}})]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(_vm.getOrder(item.orderId).commentCount > 0)?[_c('v-icon',{staticClass:"grey--text"},[_vm._v("mdi-comment-text-outline")]),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.getOrder(item.orderId).commentCount))])]:_vm._e()]}}],null,true)})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }