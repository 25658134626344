<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <template-layout>
        <template slot="content">
            <order-overview
                :active.sync="showOverviewDrawer"
                :order="selectedOrder"
            />

            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Armado del {{ selectedDate | moment('dddd D [de] MMMM') }}</span>
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-menu
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon>mdi-calendar-edit</v-icon></v-btn>
                            </template>

                            <v-date-picker
                                v-model="selectedDate"
                                @input="dateMenu = false"
                                no-title
                                :first-day-of-week="1"
                                locale="es-AR"
                                event-color="green lighten-1"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-btn text small color="primary" @click="onPackClicked" :loading="isSaving">
                            <v-icon class="mr-2">mdi-archive-outline</v-icon><span class="d-none d-sm-flex">Armar</span>
                        </v-btn>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <packing-list-table
                            :shippingItems="filteredShippingItems"
                            @row-selected="onRowSelected"
                        ></packing-list-table>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import PackingListTable from './PackingListTable';
    import OrderOverview from '@/components/Order/OrderOverview';

    export default {
        components: {
            PackingListTable,
            TemplateLayout,
            OrderOverview
        },

        data() {
            return {
                showOverviewDrawer: false,

                isSaving: false,

                shippingItems: [],
                selectedOrder: null,

                selectedDate: this.$moment().format('YYYY-MM-DD'),
                dateMenu: false
            };
        },

        computed: {
            filteredShippingItems() {
                return this.shippingItems;
            }
        },

        watch: {
            selectedDate() {
                this.fetchOrders();
            }
        },

        mounted() {
            this.fetchOrders();
        },

        methods: {
            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            async fetchOrders() {
                this.isLoading = true;

                this.shippingItems = [];

                let result = await this.$store.dispatch('shipments/fetchAll', {
                    date: this.selectedDate,
                    state: 'state.preparing'
                });

                let shipments = result.map(s => this.$store.getters['shipments/getById'](s));

                let orderIds = shipments.map(s => s.orderId);

                await this.$store.dispatch('order/fetchMany', {
                    ids: orderIds
                });

                this.shippingItems = shipments;

                this.isLoading = false;
            },

            async onPackClicked() {
                let ids = [];
                for(let o of this.shippingItems) {
                    ids.push(o.orderId);
                }

                this.isSaving = true;

                try {
                    await this.$store.dispatch('shipments/pack', {
                        shipments: this.shippingItems
                    });
                }
                finally {
                    this.isSaving = false;
                }
            },

            onRowSelected(shippingItem) {
                this.showOverviewDrawer = true;
                this.selectedOrder = this.getOrder(shippingItem.orderId);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
