<template>
    <v-expansion-panels flat>
        <v-expansion-panel v-bind:key="key" v-for="(value, key) in shippingItemsByVehicle">
            <v-expansion-panel-header class="body-1">Móvil {{ key }} <span class="grey--text">({{ value.length }})</span></v-expansion-panel-header>

            <v-expansion-panel-content>
                <v-data-table
                    class="elevation-0"
                    hide-default-footer
                    hide-default-header
                    item-key="id"
                    :headers="headers"
                    :items="value"
                    :items-per-page="-1"
                    :loading="isLoading"
                    no-data-text="No hay pedidos para mostrar"
                    sort-by="id"
                    sort-desc
                    @click:row="onRowSelected"
                    :mobile-breakpoint="0"
                >
                    <template v-slot:group.header="{group, items, toggle}">
                        <td>
                            <v-btn class="pa-0 ma-0" icon @click="toggle"><v-icon>mdi-chevron-down</v-icon></v-btn>
                            <span class="title">
                                
                            </span>
                        </td>
                    </template>

                    <template v-slot:item.customer="{ item }">
                        {{ getCustomer(getOrder(item.orderId).customer).fullName | capitalize }}
                    </template>

                    <template v-slot:item.id="{ item }">
                        {{ item.id }}
                    </template>

                    <template v-slot:item.orderId="{ item }">
                        <v-icon class="mr-2 d-none d-sm-inline grey--text">mdi-cart-outline</v-icon>
                        {{ item.orderId }}
                    </template>

                    <template v-slot:item.state="{ item }">
                        <tui-shipping-state-chip :shrink="true" :state="item.state"></tui-shipping-state-chip>
                    </template>

                    <template v-slot:item.icons="{item}">
                        <template v-if="getOrder(item.orderId).commentCount > 0">
                            <v-icon class="grey--text">mdi-comment-text-outline</v-icon>
                            <span class="mr-2">{{ getOrder(item.orderId).commentCount }}</span>
                        </template>
                    </template>
                </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import TuiShippingStateChip from '@/ui/TUIShippingStateChip';

    export default {
        components: {
            TuiShippingStateChip
        },

        data() {
            return {
                isLoading: false,
                selectedRows: [],
                headers: [
                    { text: '', value: 'id' },
                    { text: '', value: 'orderId' },
                    { text: '', value: 'customer' },
                    { text: '', value: 'deliveryDate' },
                    { text: '', value: 'state' },
                    { text: '', value: 'icons', align: 'end' }
                ]
            };
        },

        props: {
            selected: Array,
            shippingItems: Array
        },

        computed: {
            shippingItemsByVehicle() {
                let groups = this.shippingItems.reduce((rv, x) => {
                    if(x.route && x.route.vehicle) {
                        const group = x.route.vehicle.id;
                        (rv[group] = rv[group] || []).push(x);
                    }

                    return rv;
                }, {});

                return groups;
            }
        },

        watch: {
            selected(val) {
                this.selectedRows = val;
            },

            selectedRows(val) {
                this.$emit('update:selected', val);
            }
        },

        methods: {
            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            groupBy(items, groupBy) {
                const key = groupBy[0];
                let groups = items.reduce((rv, x) => {
                    const group = x[key].id;
                    (rv[group] = rv[group] || []).push(x);

                    return rv;
                }, {});

                return groups;
            },

            onRowSelected(order) {
                this.$emit('row-selected', order);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-data-table {
        thead {
            tr:last-child {
                th {
                    border: none !important;
                }
            }
        }
    }
</style>
